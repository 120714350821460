import { Controller } from "@hotwired/stimulus"

export default class GalleryController extends Controller {
  static targets = [ 'preview', 'image', 'control', 'container', 'stockText', 'zoom', 'zoomTrigger' ]

  connect() {
    this.index = 0
    const isContainerScrollable = this.containerTarget.scrollHeight > this.containerTarget.clientHeight

    // Toggle button visibility if the gallery preview is scrollable
    if (isContainerScrollable) {
      this.controlTarget.classList.toggle('hidden')
    }
  }

  controlClick(event) {
    const parentNode = event.target.parentNode
    const imgSrc = parentNode.dataset.src
    this.index = parseInt(parentNode.dataset.index);
    const imgIndex = parseInt(parentNode.dataset.index)
    const stockTextElements = this.stockTextTargets
    const textElement = document.getElementById(`stock-text-for-${imgIndex}`)
    const isStockImage = !!textElement
    this.imageTarget.src = imgSrc;
    this.zoomTarget.src = imgSrc;

    if (isStockImage) {
      this.zoomTriggerTarget.classList.add('hidden')
    } else {
      this.zoomTriggerTarget.classList.remove('hidden')
    }

    this.previewTargets.forEach((preview, index) => {
      if (index === imgIndex) {
        preview.classList.add('active');
        stockTextElements?.forEach(node => node.classList.add('hidden'))
        textElement?.classList.remove('hidden')
      } else {
        preview.classList.remove('active');
        stockTextElements.forEach(node => node.classList.add('hidden'))
        textElement?.classList.remove('hidden')
      }
    });
  }

  nextImage() {
    if (this.previewTargets.length === this.index + 1) return;

    const nextIndex = this.index + 1
    const imgSrc = this.previewTargets[nextIndex].dataset.src;
    const stockTextElements = this.stockTextTargets
    const textElement = document.getElementById(`stock-text-for-${nextIndex}`)

    this.index = nextIndex


    this.imageTarget.src = imgSrc;
    this.previewTargets.forEach((preview, index) => {
      if (index === this.index) {
        preview.classList.add('active');
        stockTextElements?.forEach(node => node.classList.add('hidden'))
        textElement?.classList.remove('hidden')
      } else {
        preview.classList.remove('active');
        stockTextElements.forEach(node => node.classList.add('hidden'))
        textElement?.classList.remove('hidden')
      }
    });
  }
}
