import { Controller } from "@hotwired/stimulus"
import axios from "axios";

export default class BrandsController extends Controller {

  static targets = ['form', 'search', 'buttonGroup']

  initialize() {
    this.params = { condition: this.formTarget.dataset.initialTab }
  }

  connect() {
    this.sectionId = this.formTarget.dataset.sectionId
    this.offerIds = this.formTarget.dataset.offerIds.split(',')
    this.formTarget.addEventListener('submit', this.formSubmitHandler.bind(this))
  }

  mergeParams(queryParams) {
    this.params = { ...this.params, ...queryParams }
    return this.params
  }

  formSubmitHandler(event) {
    event.preventDefault();
    const search = this.searchTarget.value
    const idsQuery = this.formTarget.dataset.ids
    const queryParams = search.trim().length ? { brand_query: search } : { brand_query: null }
    this.fetchResults(idsQuery, this.mergeParams(queryParams))
  }


  fetchConditionResults(event) {
    const idsQuery = this.formTarget.dataset.ids
    const search = this.searchTarget.value
    document.querySelector(`button.active.button-${this.sectionId}`)?.classList?.toggle('active')
    event.target.classList.toggle('active')
    const searchParams = search.trim().length ? { brand_query: search } : { brand_query: null }
    this.fetchResults(idsQuery, this.mergeParams({ ...event.params, ...searchParams }))
  }

  fetchCapaResults(event) {
    const idsQuery = this.formTarget.dataset.ids
    this.fetchResults(idsQuery, this.mergeParams({ capa_certified: event.target.checked }))
  }

  async fetchResults(queryIds = '', params = {}) {
      try {
      const { data } = await axios.get(`/offers.json?${queryIds}`, {
        params
      });
      this.updateResults(data, params.brand_query, params.condition)
    } catch (error) {
      console.error('Failed to load: ', error)
    }
  }

  updateResults(data, search, condition) {
    const noResultsElement = document.getElementById(`list-no-results-${this.sectionId}`);
    const messageElement = document.getElementById(`no-results-message-${this.sectionId}`);
    if (data.length && !noResultsElement.classList.contains('hidden')) {
      noResultsElement.classList.add('hidden')
    }

    this.offerIds.forEach(offerId => {
      if (data.find(({ id }) => id === parseInt(offerId))) {
        document.getElementById(`offer-card-${offerId}`).classList.remove('hidden')
      } else {
        document.getElementById(`offer-card-${offerId}`).classList.add('hidden')
      }
    });

    if (!data.length) {
      messageElement.innerHTML = condition ? `No “${condition}” offers ` : `No offers for brand “${search}”`;
      if (noResultsElement.classList.contains('hidden')) {
        noResultsElement.classList.remove('hidden');
      }
    }
  }
}
