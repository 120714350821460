import { Controller } from "@hotwired/stimulus"

export default class ZoomController extends Controller {
    static targets = ['image', 'container', 'close']

    initialize() {
        this.zoomActive = false
    }

    connect() {
        this.containerTarget.addEventListener('click', this.handleZoomClick.bind(this));
        this.closeTarget.addEventListener('click', this.handleZoomClick.bind(this));
    }

    disconnect() {
        this.containerTarget.removeEventListener('click', this.handleZoomClick.bind(this));
        this.closeTarget.removeEventListener('click', this.resetZoom.bind(this));
    }

    handleZoomClick() {
        if (this.zoomActive) {
            this.resetZoom()
        } else {
            this.zoomActive = true;
            this.imageTarget.style.transform = 'scale(2)'
            this.containerTarget.addEventListener('mousemove', this.move.bind(this));
            this.containerTarget.addEventListener('touchmove', this.move.bind(this));
        }
    }

    resetZoom() {
        this.zoomActive = false;
        this.imageTarget.style.transform = ''
        this.imageTarget.style.top = ''
        this.imageTarget.style.left = ''
        this.containerTarget.removeEventListener('mousemove', this.move.bind(this));
        this.containerTarget.removeEventListener('touchmove', this.move.bind(this));
    }

    changeImage(e) {
        this.resetZoom();
        this.imageTarget.src = e.target.src
    }

    move(e){
        if (!this.zoomActive) {
            return false;
        }

        this.imageTarget.style.left = e.pageX - this.containerTarget.offsetWidth / 2 + 'px';
        this.imageTarget.style.top = e.pageY - this.containerTarget.offsetHeight / 2 + 'px';
    }


}
